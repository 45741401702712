export const ROUTES_PATHS = {
  HOME: "/",
  OFFERS: "/cred-now/offers",
  OFFERS_DETAILS: "/cred-now/offers/details",
  ABOUT_US: "/cred-now/about-us",
  PRIVACY_POLICY: "/cred-now/privacy-policy",
  PROFILE: "/cred-now/profile",
  PERSONAL_LOAN: "/best-personal-loan-in-india",
  CREDIT_CARD: "/best-credit-cards-in-india",
  BLOGS: "https://blog.crednow.in",
};
