import toast from "cg-toast";

//helper functions imports
import { AxiosError, AxiosResponse } from "axios";
import { store } from "../../store";
import { saveUserDetails, setMobile, setTab } from "../../store/config";
import {
  ApiResponse,
  CategoriesDetailsProps,
  FAQProps,
  FieldProps,
  FooterProps,
  MenuOptionsProps,
  ContentProps,
  TestimonialProps,
  CreditCardDataProps,
  ObjectBasicProps,
} from "../interface/common";
import API_SERVICE from "../services/apiService";
import { API_ENDPOINTS } from "../services/constants";
import { logout } from "../../store/logout";
import { ROUTES_PATHS } from "./routes";
import { NavigateFunction } from "react-router-dom";

//constant values
export const NUMBER_REGEX = /^\d+$/;
export const INTEREST_RATE_REGEX = /^\d+(\.\d{0,2})?$/;
export const MOBILE_NUMBER_REGEX = /^[6-9]\d{9}$/;
export const PINCODE_REGEX = /^[1-9][0-9]{5}$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PAN_VALIDATION_REGEX = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
export const DEFAULT_ERROR_MESSAGE = "Something went wrong";
export const UNAUTHORIZED_MESSAGE = "Unauthorized, Redirecting to Home Page";
export const MOBILE_NUMBER_ERROR =
  "Please enter Mobile number in correct format";
export enum CATEGORIES {
  PERSONAL_LOAN = "personal_loan",
  CREDIT_CARDS = "credit_cards",
  MICRO_LOANS = "micro_loans",
  BUSINESS_LOANS = "business_loans",
  BLOGS = "blogs",
}
export const SESSION_STORAGE_KEYS: { [key: string]: string } = {
  PRODUCT_DETAILS: "product_details",
};
export const utmParams: string[] = [
  "utm_source",
  "utm_campaign",
  "utm_term",
  "utm_medium",
  "utm_content",
  "utm_id",
];
export const PERSONAL_DETAILS_KEYS: string[] = [
  "name",
  "mobile",
  "dob",
  "email",
  "address",
  "pincode",
  "occupation",
  "monthly_salary",
];
export const MENU_ITEMS: MenuOptionsProps[] = [
  { label: "Personal Loan", value: CATEGORIES.PERSONAL_LOAN },
  { label: "Credit Cards", value: CATEGORIES.CREDIT_CARDS },
];
export const CREDIT_CARDS_FIELDS: MenuOptionsProps[] = [
  { label: "Joining Fees", value: "joining_fees" },
  { label: "Annual Fees", value: "annual_fee" },
];
export const CREDIT_CARDS: CreditCardDataProps = {
  travel: {
    title: "Travel",
    description:
      "Discover the Best Travel Credit Cards—your ideal travel companion. Earn rewards with every spend, enjoy exclusive deals, and unlock privileges that enhance your adventures. Turn your journeys into rewarding experiences. Apply now and make every trip count!",
    data: [
      {
        product_id: "36",
        category_id: "3",
        product_name: "Axis Bank Vistara",
        product_image_link: require("../../assets/axis_bank_vistara.webp"),
        amount_range: "",
        tenure_range: "",
        interest_rate_range: "",
        processing_fee: "",
        annual_fee: "1500",
        joining_fee: "1500",
        features: "Travel, Lifestyle",
        amount_min_max: "",
        benefits: "",
        client: "banksathi",
        fee_wavier: "Yes",
      },
      {
        product_id: "36",
        category_id: "3",
        product_name: "Axis Bank Vistara Signature",
        product_image_link: require("../../assets/axis_bank_vistara_signature.webp"),
        amount_range: "",
        tenure_range: "",
        interest_rate_range: "",
        processing_fee: "",
        annual_fee: "3000",
        joining_fee: "3000",
        features: "Travel, Lifestyle",
        amount_min_max: "",
        benefits: "",
        client: "banksathi",
        fee_wavier: "Yes",
      },
    ],
  },
  rewards: {
    title: "Rewards",
    description:
      "Experience the magic of our Best Reward Credit Cards, where every spend brings exciting benefits like travel rewards, exclusive merchandise, or cashback. While the perks are plentiful, keep in mind the higher fees and interest rates. Ready for endless privileges? Apply today!",
    data: [
      {
        product_id: "36",
        category_id: "3",
        product_name: "Axis Magnus Credit Card",
        product_image_link: require("../../assets/axis_magnus.webp"),
        amount_range: "",
        tenure_range: "",
        interest_rate_range: "",
        processing_fee: "",
        annual_fee: "12500",
        joining_fee: "12500",
        features: "Shopping, Travel, Rewards, Lifestyle",
        amount_min_max: "",
        benefits: "",
        client: "banksathi",
        fee_wavier: "Yes",
      },
      {
        product_id: "36",
        category_id: "3",
        product_name: "Axis Neo Credit Card",
        product_image_link: require("../../assets/axis_neo.webp"),
        amount_range: "",
        tenure_range: "",
        interest_rate_range: "",
        processing_fee: "",
        annual_fee: "250",
        joining_fee: "250",
        features: "Shopping, Travel, Rewards, Lifestyle",
        amount_min_max: "",
        benefits: "",
        client: "banksathi",
        fee_wavier: "Yes",
      },
      {
        product_id: "74",
        category_id: "3",
        product_name: "IDFC First Classic Credit Card",
        product_image_link: require("../../assets/idfc_firstclass.png"),
        amount_range: "",
        tenure_range: "",
        interest_rate_range: "",
        processing_fee: "",
        annual_fee: "",
        joining_fee: "",
        features: "Rewards, Travel",
        amount_min_max: "",
        benefits: "",
        client: "banksathi",
        fee_wavier: "Yes",
      },
    ],
  },
  shopping: {
    title: "Shopping",
    description:
      "Step into a rewarding shopping experience with Best Shopping Credit Cards. Unlock perks with every rupee spent, whether online or in-store. Enjoy benefits on everything from electronics to groceries. Turn your purchases into rewards and savings. Apply now to start enjoying exclusive privileges!",
    data: [
      {
        product_id: "36",
        category_id: "3",
        product_name: "Flipkart Axis Bank Credit Card",
        product_image_link: require("../../assets/flipkart_axis.png"),
        amount_range: "",
        tenure_range: "",
        interest_rate_range: "",
        processing_fee: "",
        annual_fee: "499",
        joining_fee: "499",
        features: "Shopping, Rewards, Lifestyle",
        amount_min_max: "",
        benefits: "",
        client: "banksathi",
        fee_wavier: "Yes",
      },
      {
        product_id: "74",
        category_id: "3",
        product_name: "IDFC First Power Plus Credit Card",
        product_image_link: require("../../assets/idfc_plus.jpeg"),
        amount_range: "",
        tenure_range: "",
        interest_rate_range: "",
        processing_fee: "",
        annual_fee: "499",
        joining_fee: "499",
        features: "Shopping, Rewards, Lifestyle",
        amount_min_max: "",
        benefits: "",
        client: "banksathi",
        fee_wavier: "Yes",
      },
    ],
  },
  fuel: {
    title: "Fuel",
    description:
      "Welcome to the world of Best Fuel Credit Cards, where every refuel rewards you. Earn cashback and savings with each fuel purchase, making every drive more rewarding. From gas stations to long journeys, enjoy financial benefits with every swipe. Turn every tank of fuel into a step towards greater savings. Apply now and start fueling your savings today!",
    data: [
      {
        product_id: "36",
        category_id: "3",
        product_name: "Axis Bank Indian Oil Credit Card",
        product_image_link: require("../../assets/axis_fuel.webp"),
        amount_range: "",
        tenure_range: "",
        interest_rate_range: "",
        processing_fee: "",
        annual_fee: "500",
        joining_fee: "500",
        features: "Fuel, Rewards, Lifestyle",
        amount_min_max: "",
        benefits: "",
        client: "banksathi",
        fee_wavier: "Yes",
      },
    ],
  },
  cashback: {
    title: "Cash-back",
    description:
      "Checkout these cards where every transaction puts money back in your wallet. Earn rewards on dining, travel, and shopping, turning every purchase into savings. Enjoy the cycle of spending and earning. Apply now to start growing your savings with every swipe!",
    data: [
      {
        product_id: "182",
        category_id: "3",
        product_name: "HDFC Moneyback Credit Card",
        product_image_link: require("../../assets/hdfc_moneyback.jpg"),
        amount_range: "",
        tenure_range: "",
        interest_rate_range: "",
        processing_fee: "",
        annual_fee: "499",
        joining_fee: "499",
        features: "Cashback, Lifestyle",
        amount_min_max: "",
        benefits: "",
        client: "banksathi",
        fee_wavier: "Yes",
      },
      {
        product_id: "182",
        category_id: "3",
        product_name: "HDFC Bank UPI Rupay Credit Card",
        product_image_link: require("../../assets/hdfc_rupay.jpeg"),
        amount_range: "",
        tenure_range: "",
        interest_rate_range: "",
        processing_fee: "",
        annual_fee: "499",
        joining_fee: "499",
        features: "Cashback, Lifestyle, UPI",
        amount_min_max: "",
        benefits: "",
        client: "banksathi",
        fee_wavier: "Yes",
      },
    ],
  },
};
export const CREDIT_CARDS_DETAILS_FIELDS: MenuOptionsProps[] = [
  { label: "Joining Fees", value: "joining_fees" },
  { label: "Annual Fees", value: "annual_fee" },
];
export const OTHER_CATEGORIES_FIELDS: MenuOptionsProps[] = [
  { label: "Amount", value: "amount_range" },
  { label: "Tenure", value: "tenure_range" },
  { label: "Interest", value: "interest_rate_range" },
];
export const OTHER_CATEGORIES_DETAILS_FIELDS: MenuOptionsProps[] = [
  { label: "Amount", value: "amount_range" },
  { label: "Tenure", value: "tenure_range" },
  { label: "Interest", value: "interest_rate_range" },
  { label: "Processing Fees", value: "processing_fee" },
];
export const STEPS_LABELS: string[] = [
  "PAN Verification",
  "Personal Information",
];
export const PAN_VALIDATION_FIELDS: FieldProps[] = [
  {
    name: "mobile",
    label: "Mobile Number",
    type: "inputField",
    required: true,
    value: "",
  },
  {
    name: "pan_number",
    label: "PAN Number",
    type: "inputField",
    required: true,
    value: "",
  },
];
export const PERSONAL_DETAILS_FIELDS: FieldProps[] = [
  {
    name: "name",
    label: "Full Name",
    type: "inputField",
    required: false,
    value: "",
  },
  {
    name: "dob",
    label: "Date of Birth",
    type: "dateField",
    required: false,
    value: "",
  },
  {
    name: "email",
    label: "Email",
    type: "inputField",
    required: true,
    value: "",
  },
  {
    name: "occupation",
    label: "Select Occupation",
    type: "selectField",
    required: true,
    value: "",
    options: [
      { label: "Salaried", value: "Salaried" },
      { label: "Self-Employed", value: "Self Employed" },
    ],
  },
  {
    name: "monthly_salary",
    label: "Enter Monthly Salary",
    required: true,
    value: "",
    type: "inputField",
  },
  {
    name: "gender",
    label: "Choose Gender",
    value: "",
    required: true,
    type: "selectField",
    options: [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
      { label: "Others", value: "others" },
    ],
  },
  {
    name: "pincode",
    label: "Pin-code",
    value: "",
    required: false,
    type: "inputField",
  },
  {
    name: "required_loan_amount",
    label: "Required Loan Amount",
    type: "inputField",
    required: false,
    value: "",
  },
];
export const PROFILE_DETAILS_FIELDS: FieldProps[] = [
  {
    name: "name",
    label: "Full Name",
    type: "inputField",
    required: false,
    value: "",
  },
  {
    name: "dob",
    label: "Date of Birth",
    type: "dateField",
    required: false,
    value: "",
  },
  {
    name: "email",
    label: "Email",
    type: "inputField",
    required: false,
    value: "",
  },
  {
    name: "address",
    label: "Address",
    type: "inputField",
    required: false,
    value: "",
  },
  {
    name: "pincode",
    label: "Pin-code",
    value: "",
    required: false,
    type: "inputField",
  },
  {
    name: "mobile",
    label: "Mobile",
    value: "",
    required: false,
    type: "inputField",
  },
  {
    name: "pan_number",
    label: "PAN",
    value: "",
    required: false,
    type: "inputField",
  },
  {
    name: "occupation",
    label: "Select Occupation",
    type: "selectField",
    required: false,
    value: "",
    options: [
      { label: "Salaried", value: "Salaried" },
      {
        label: "Self Employed (Professional)",
        value: "Self Employed (Professional)",
      },
      { label: "Self-Employed", value: "Self Employed" },
    ],
  },
  {
    name: "monthly_salary",
    label: "Enter Monthly Salary",
    required: false,
    value: "",
    type: "inputField",
  },
];
export const PROFESSION_OPTIONS: MenuOptionsProps[] = [
  { label: "Small Business Owner", value: "Small Business Owner" },
  { label: "Doctor", value: "Doctor" },
  { label: "Driver/House Help", value: "Driver/House Help" },
  { label: "Daily Wage Employee", value: "Daily Wage Employee" },
  {
    label: "Electrician/Plumber/Skilled Worker",
    value: "Electrician/Plumber/Skilled Worker",
  },
  { label: "CA/CS/ICWA", value: "CA/CS/ICWA" },
  {
    label: "Architect/Interior Designer",
    value: "Architect/Interior Designer",
  },
  { label: "Lawyer/Paralegal", value: "Lawyer/Paralegal" },
  { label: "Contractual Employee", value: "Contractual Employee" },
  {
    label: "Actor/Influencer/Director/Photographer",
    value: "Actor/Influencer/Director/Photographer",
  },
  { label: "Blogger/Journalist", value: "Blogger/Journalist" },
  { label: "Freelancer", value: "Freelancer" },
  { label: "Delivery Executive", value: "Delivery Executive" },
  { label: "Insurance/Property Agent", value: "Insurance/Property Agent" },
  {
    label: "Nutritionist/Health & Wellness Trainer",
    value: "Nutritionist/Health & Wellness Trainer",
  },
  { label: "Teacher/Professor/Lecturer", value: "Teacher/Professor/Lecturer" },
  { label: "Cook/Catering Business", value: "Cook/Catering Business" },
  { label: "Fashion/Textile Designer", value: "Fashion/Textile Designer" },
  { label: "Agriculturist", value: "Agriculturist" },
  { label: "House Wife", value: "House Wife" },
  { label: "Engineer", value: "Engineer" },
  { label: "Others", value: "Others" },
];
export const RESIDENCE_TYPE_OPTIONS: MenuOptionsProps[] = [
  { label: "Self Owned", value: "Self Owned" },
  { label: "Owned by Parents", value: "Owned by Parents" },
  { label: "Owned by Siblings", value: "Owned by Siblings" },
  { label: "Rented", value: "Rented" },
];
export const RESIDENCE_YEARS_OPTIONS: MenuOptionsProps[] = [
  { label: "0-3 Months", value: "0-3 Months" },
  { label: "3-6 Months", value: "3-6 Months" },
  { label: "6 Months-1 Year", value: "6 Months-1 year" },
  { label: "1-2 Year", value: "1-2 Year" },
  { label: "2+ Years", value: "2+ Years" },
];
export const QUALIFICATION_OPTIONS: MenuOptionsProps[] = [
  { label: "Under Graduation", value: "Under Gradudation" },
  { label: "Graduation", value: "Graduation" },
  { label: "Post Graduation", value: "Post Graduation" },
  { label: "Others", value: "Others" },
];
export const PRODUCTS_LISTS: ContentProps[] = [
  {
    value: CATEGORIES.PERSONAL_LOAN,
    title: "Personal Loan",
    description: "Get started on making your dreams a reality today!",
    image_url: require("../../assets/personalloan.svg")?.default,
  },
  {
    value: CATEGORIES.CREDIT_CARDS,
    title: "Credit Cards",
    description: "Rewards, cashback, travel benefits, and more await!",
    image_url: require("../../assets/creditcard.svg")?.default,
  },
  {
    value: CATEGORIES.MICRO_LOANS,
    title: "Micro Loans Under (5 lakhs)",
    description:
      "Micro loan offers designed for quick processing with minimal paperwork",
    image_url: require("../../assets/microloan.svg")?.default,
  },
];
export const FOOTER_DATA: FooterProps = {
  "Follow us on": [
    {
      key: "facebook",
      name: "Facebook",
      link: "https://www.facebook.com/profile.php?id=61558887493659",
      url: require("../../assets/facebook.svg")?.default,
    },
    {
      key: "instagram",
      name: "Instagram",
      link: "https://www.instagram.com/crednow.in/",
      url: require("../../assets/instagram.svg")?.default,
    },
    {
      key: "linkedin",
      name: "Linkedin",
      link: "https://www.linkedin.com/company/crednow-in/",
      url: require("../../assets/linkedin.svg")?.default,
    },
  ],
  Support: [
    { name: "About Us", link: "", key: "about_us" },
    { name: "Privacy Policy", link: "", key: "privacy_policy" },
    { name: "Blogs", link: "https://blog.crednow.in/", key: "blog" },
  ],
  "Contact Information": [
    {
      name: "support@crednow.in",
      link: "",
      key: "email",
      sub_name: "Email Address: ",
    },
    {
      name: "85880 06523",
      link: "",
      key: "mobile",
      sub_name: "Contact Number: ",
    },
    {
      name: `Headquarters: LG Floor, Smartworks, World Trade Tower, Sector - 16, Noida 201301
      Delhi Office: E-44/3 Okhla, Phase-2, New Delhi-110020`,
      link: "",
      key: "address",
      sub_name: "Address: ",
    },
  ],
};
export const PRIVACY_POLICY_DATA: MenuOptionsProps[] = [
  {
    label: "Introduction",
    value:
      "Welcome to CredNow, the lending offer aggregator app (CredNow,'we,' 'us,' or 'our'). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, share, and protect your data when you use our app or website.",
  },
  {
    label: "Information We Collect",
    value:
      "We collect personal information from you when you use our app or website, including but not limited to: Contact Information (name, email address, phone number Financial Information (income, credit score, loan requirements) Demographic Information (age, gender, location)",
  },
  {
    label: "How We Use Your Information",
    value:
      "We use the information we collect for various purposes, including: Providing and personalising our services, Facilitating loan comparisons and applications Communicating with you about your account and relevant offers, Improving our app and website and Complying with legal requirements",
  },
  {
    label: "Data Security",
    value:
      "We implement security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These measures include encryption, access controls, and regular security audits. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.",
  },
  {
    label: "Sharing Your Information",
    value:
      "We may share your information with trusted third parties, including lenders and financial institutions, for the purpose of facilitating loan comparisons and applications. We do not sell or rent your personal information to third parties for marketing purposes.",
  },
  {
    label: "Your Choices",
    value:
      "You can choose not to provide certain information, but this may limit your ability to use certain features of our app or website. You can also opt-out of receiving promotional communications from us by following the instructions in the communication or contacting us directly.",
  },
  {
    label: "Changes to This Privacy Policy",
    value:
      "We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the new Privacy Policy on our website or app.",
  },
];
export const CATEGORIES_DATA: CategoriesDetailsProps = {
  [CATEGORIES.PERSONAL_LOAN]: {
    features: [
      "Find and Compare the Best Personal Loan Offers",
      "Quick & Easy Approval Process",
      "Attractive Interest Rates on Personal Loans",
      "Personalized Options to Suit You",
    ],
    image_url: require("../../assets/personal_loan.svg")?.default,
  },
  [CATEGORIES.CREDIT_CARDS]: {
    features: [
      "A Wide Range of Card Options",
      "Easy Application Process",
      "Find and Compare Benefits",
      "Exclusive Credit Cards Rewards & Offers",
    ],
    image_url: require("../../assets/credit_card.svg")?.default,
  },
  [CATEGORIES.MICRO_LOANS]: {
    features: [
      "Best Micro Loan Offers from Trusted Lenders",
      "Quick & Easy Application Process",
      "Attractive Interest Rates on Micro Loans",
      "Personalized to Suit Your Specific Needs",
    ],
    image_url: require("../../assets/micro_loan.svg")?.default,
  },
};

export const CHOOSE_REASONS: ContentProps[] = [
  {
    title: "Network of Trusted Lenders",
    description: "Connect in one place with the best lenders in India.",
    image_url: require("../../assets/streamline.svg")?.default,
  },
  {
    title: "Speed and Simplicity",
    description: "Easy and fast process with CredNow's user-friendly platform.",
    image_url: require("../../assets/speed.svg")?.default,
  },
  {
    title: "Excellent Customer Support",
    description: "Quick assistance to answer all your queries and issues.",
    image_url: require("../../assets/support.svg")?.default,
  },
];
export const CHOOSE_REASONS_PERSONAL_LOANS: ContentProps[] = [
  {
    title: "Comprehensive Comparison Without Credit Impact",
    description:
      "Compare personal loan offers from leading partner banks and lenders without negatively impacting your credit score.",
    image_url: require("../../assets/comprehensive.svg")?.default,
  },
  {
    title: "Know Your Approval Chances",
    description:
      "Understand your likelihood of loan approval based on your credit score and other credit profile aspects, helping you make informed decisions.",
    image_url: require("../../assets/speed.svg")?.default,
  },
  {
    title: "Instant Pre-Approved Offers",
    description:
      "Get access to the best pre-approved offers with quick disbursals, ensuring you get the funds you need swiftly.",
    image_url: require("../../assets/support.svg")?.default,
  },
];
export const CHOOSE_REASONS_CREDIT_CARDS: ContentProps[] = [
  {
    title: "Unbiased Comparisons",
    description:
      "We provide objective information on a wide range of credit cards, helping you make an informed decision",
    image_url: require("../../assets/comprehensive.svg")?.default,
  },
  {
    title: "Digital Application Process",
    description:
      "Apply for the card online in minutes by providing some basic details. Our streamlined process makes getting started quick and easy.",
    image_url: require("../../assets/speed.svg")?.default,
  },
  {
    title: "Up-to-Date Offers",
    description:
      "Our platform is constantly updated with the latest credit card offers and promotions.",
    image_url: require("../../assets/support.svg")?.default,
  },
];
export const NEXT_STEP_DATA_PERSONAL_LOANS: ContentProps = {
  title: "Ready to take the next step?",
  description:
    "Apply for an instant personal loan with CredNow today and take the first step towards financial freedom. Our user-friendly platform and dedicated customer support team are here to guide you through every step of the process.",
};
export const NEXT_STEP_DATA_CREDIT_CARDS: ContentProps = {
  title: "Ready to Find Your Perfect Credit Card ?",
  description:
    "Start comparing the best credit cards online with CredNow today and take control of your financial future. Our expert team is here to guide you every step of the way.",
};
export const KEY_FEATURES_BENEFITS_PERSONAL_LOANS: ContentProps[] = [
  {
    title: "Fast Approval",
    description:
      "Get your loan approved in minutes with our quick and straightforward application process.",
    image_url: require("../../assets/fast_approval.svg")?.default,
  },
  {
    title: "Flexible Repayment",
    description:
      "Choose from a variety of repayment plans that fit your budget and timeline.",
    image_url: require("../../assets/flexible_repayment.svg")?.default,
  },
  {
    title: "Competitive Interest Rates",
    description:
      "Enjoy some of the most competitive interest rates in the market, making repayments easier.",
    image_url: require("../../assets/competitive.svg")?.default,
  },
  {
    title: "No Hidden Fees",
    description: "Transparent process with no hidden charges or clauses.",
    image_url: require("../../assets/hidden_fees.svg")?.default,
  },
  {
    title: "Secure Processing",
    description:
      "Your personal and financial information is safe with our advanced security measures.",
    image_url: require("../../assets/processing.svg")?.default,
  },
];
export const KEY_FEATURES_BENEFITS_CREDIT_CARDS: ContentProps[] = [
  {
    title: "Comprehensive Comparison",
    description:
      "Compare multiple credit cards side-by-side to find the perfect match for your needs.",
    image_url: require("../../assets/competitive.svg")?.default,
  },
  {
    title: "Tailored Recommendations",
    description:
      "Answer a few questions, and we'll suggest the best credit cards for your specific profile.",
    image_url: require("../../assets/recommendations.svg")?.default,
  },
  {
    title: "Instant Approval",
    description:
      "Many of our partner cards offer instant approval decisions, so you can start using your card sooner",
    image_url: require("../../assets/fast_approval.svg")?.default,
  },
  {
    title: "Rewards Maximizer",
    description:
      "Find cards that offer the best rewards for your spending habits, whether it's cashback, travel miles, or points.",
    image_url: require("../../assets/reward.svg")?.default,
  },
  {
    title: "Security First",
    description:
      "Apply with confidence knowing that your personal information is protected by advanced encryption.",
    image_url: require("../../assets/security.svg")?.default,
  },
];
export const PROCEDURE_CONTENT_CREDIT_CARDS: ContentProps[] = [
  {
    description:
      "The best credit card offers from leading banks and financial institutions.",
    image_url: require("../../assets/procedure_one.svg")?.default,
  },
  {
    description:
      "A user-friendly comparison tool to find the best credit card for you.",
    image_url: require("../../assets/procedure_two.svg")?.default,
  },
  {
    description: "Transparent information on rates, fees, and rewards.",
    image_url: require("../../assets/procedure_three.svg")?.default,
  },
  {
    description: "Quick online applications with instant approval decisions.",
    image_url: require("../../assets/procedure_four.svg")?.default,
  },
  {
    description: "Expert guidance throughout your credit card journey.",
    image_url: require("../../assets/procedure_five.svg")?.default,
  },
];
export const JOIN_REASONS: ContentProps[] = [
  {
    title: "Quick Approval",
    image_url: require("../../assets/one.svg")?.default,
  },
  {
    title: "Easy Process",
    image_url: require("../../assets/three.svg")?.default,
  },
  {
    title: "Trustworthy Lenders",
    image_url: require("../../assets/two.svg")?.default,
  },
];
export const JOIN_REASONS_CREDIT_CARDS: ContentProps[] = [
  {
    title: "Instant Approval",
    image_url: require("../../assets/approval.svg")?.default,
  },
  {
    title: "Tailored Rewards",
    image_url: require("../../assets/tailored_rewards.svg")?.default,
  },
  {
    title: "Competitive Rates",
    image_url: require("../../assets/rates.svg")?.default,
  },
];
export const TestimonialsData: TestimonialProps[] = [
  {
    gender: "female",
    content:
      "I had a superb experience with CredNow. It’s so easy to compare and choose a loan here. They give you the best loan options and make it simple to pick one.",
    name: "Neha Mathur",
    designation: "Data Analyst",
    image_url: require("../../assets/neha_mathur.png"),
  },
  {
    gender: "male",
    content:
      "CredNow is easy to use, and in just a few minutes, I secured the right credit product for my needs",
    name: "Parvesh Verma",
    designation: "Strategy Consultant",
    image_url: require("../../assets/parvesh_verma.png"),
  },
  {
    gender: "female",
    content:
      "With my busy work life, I enjoyed CredNow's instant approach to digital finance. The platform saved me valuable time by providing relevant options for loans and credit cards.",
    name: "Manish Kumar",
    designation: "Program Manager",
    image_url: require("../../assets/manish_kumar.png"),
  },
];
export const TestimonialsDataPersonalLoans: TestimonialProps[] = [
  {
    gender: "female",
    content:
      "I applied for a loan with CredNow for home renovation. The funds were disbursed promptly, and the repayment terms were very flexible. Highly recommend it!",
    name: "Priya",
    designation: "Executive Engineer",
    image_url: require("../../assets/priya.jpg"),
  },
  {
    gender: "male",
    content:
      "I needed a loan to start my small store, and CredNow made it possible. The process was smooth, and the support team was very helpful throughout. A big thank you to CredNow!",
    name: "Anuj",
    designation: "Kirana Shop Owner",
    image_url: require("../../assets/anuj.jpg"),
  },
  {
    gender: "male",
    content:
      "When I needed extra funds for my daughter's wedding, CredNow came through with a quick and easy loan process. The staff was friendly and supportive. Highly recommended!",
    name: "Ravi Sharma",
    designation: "Sales Associate",
    image_url: require("../../assets/ravi_sharma.jpg"),
  },
];
export const TestimonialsDataCreditCards: TestimonialProps[] = [
  {
    gender: "male",
    content:
      "I’ve been using CredNow for a few months now, and it has made the entire process of finding the right credit card so seamless. The recommendations were spot-on for my needs, and I was able to compare benefits across different cards easily. Their team also assisted with the documentation, which saved me a lot of time. This platform is a must for anyone looking to get the best credit card deals!",
    name: "Ravi Sharma",
    designation: "Marketing Executive, Mumbai",
    image_url: require("../../assets/ravi_sharma.jpg"),
  },
  {
    gender: "female",
    content:
      "As a working mother, I needed a credit card that would help me manage household expenses and earn rewards. CredNow not only helped me find the perfect card but also guided me through the entire application process. I’m now saving on groceries, fuel, and even my kids' school supplies! The service was excellent, and I highly recommend it to anyone in need of financial advice.",
    name: "Priya Mehra",
    designation: "Teacher, Delhi",
    image_url: require("../../assets/priya_mehra.jpg"),
  },
  {
    gender: "male",
    content:
      "I was always confused about which credit card to choose, especially since there are so many options available. CredNow simplified everything for me. With their help, I was able to select a card that suits my lifestyle, and I got approved quickly. It was my first credit card, and the experience was completely hassle-free!",
    name: "Ankit Joshi",
    designation: "Software Developer Bangalore",
    image_url: require("../../assets/ankit_joshi.jpg"),
  },
];
export const TIPS_PAGE_DATA_PERSONAL_LOANS = {
  contact_title: "Compare the best personal loan offers from top lenders",
  contact_des:
    "Secure the lowest rates and find the perfect fit for your needs",
  page_title: "Essential Tips to Secure Your Personal Loan Approval",
  page_des:
    "Obtaining a personal loan can be an ideal solution for your financial needs, but securing approval requires careful preparation. Follow these five essential tips to enhance your chances of getting approved for a personal loan:",
};
export const TIPS_PAGE_DATA_CREDIT_CARDS = {
  contact_title: "Find the Best Credit Card Offers from Top Issuers",
  contact_des:
    "Secure the lowest rates and find the perfect fit for your needs.",
  page_title: "Essential Tips for Choosing the Right Credit Card",
  page_des:
    "Choosing the right credit card can significantly impact your financial health. Consider these key points before applying: ",
};
export const TIPS_PERSONAL_LOANS: ContentProps[] = [
  {
    title: "Maintain a Strong Credit Score",
    description:
      "A credit score above 750 significantly increases your chances of approval. Regularly check your credit report for errors and rectify any inaccuracies to keep your score in top shape.",
  },
  {
    title: "Manage Your Debt-to-Income Ratio",
    description:
      "Keep your debt-to-income ratio low by ensuring that your monthly debt payments are a small percentage of your income. This reassures lenders of your repayment capacity.",
  },
  {
    title: "Choose the Right Lender",
    description:
      "Research various banks and NBFCs to find the ones with the most favourable terms and higher approval rates. Opt for lenders where you meet the eligibility criteria comfortably.",
  },
  {
    title: "Limit Multiple Applications",
    description:
      "Avoid applying for multiple loans within a short period. Each application triggers a hard inquiry on your credit report, which can lower your credit score and signal desperation to lenders.",
  },
  {
    title: "Prepare Thorough Documentation",
    description:
      "Ensure all required documents, such as income proof, identity proof, and address proof, are up-to-date and readily available. Proper documentation speeds up the approval process and portrays you as an organized and reliable borrower. ",
  },
];
export const TIPS_CREDIT_CARDS: ContentProps[] = [
  {
    title: "Understand Your Spending Habits",
    description:
      "Analyze your monthly expenses to determine which type of rewards card would benefit you most. For example, if you spend a lot on groceries and gas, a card offering high cash back rates on these categories would be ideal. If you travel frequently, consider a card with travel rewards and perks like free checked bags or airport lounge access.",
  },
  {
    title: "Check Your Credit Score",
    description:
      "Your credit score affects your approval odds and the terms you'll receive. Higher scores generally lead to better offers, including lower interest rates and higher credit limits. Check your score before applying and take steps to improve it if necessary, such as paying down existing debt and correcting any errors on your credit report.",
  },
  {
    title: "Compare Annual Percentage Rates (APRs)",
    description:
      "If you plan to carry a balance, focus on cards with lower APRs to minimize interest charges. Even a small difference in APR can significantly affect the amount of interest you’ll pay over time. Some cards offer introductory 0% APR periods, which can be beneficial if you need to make a large purchase or transfer an existing balance.",
  },
  {
    title: "Consider Annual Fees",
    description:
      "Weigh the cost of annual fees against the card's benefits to ensure it provides value for your needs. Some cards offer no annual fee but may have fewer rewards or perks, while others with higher fees provide extensive benefits, such as travel insurance, purchase protection, and concierge services. Calculate whether the benefits you’ll use outweigh the fee.",
  },
  {
    title: "Review the Card’s Terms and Conditions",
    description:
      "Carefully read the terms and conditions to understand all fees, including late payment fees, foreign transaction fees, and balance transfer fees. Knowing these details upfront can prevent unpleasant surprises and help you manage your card more effectively.",
  },
  {
    title: "Evaluate Customer Service and Support:",
    description:
      "Consider the quality of customer service offered by the credit card issuer. Good customer support can make a big difference when dealing with issues such as fraudulent charges or lost cards.",
  },
  {
    title: "Understand the Rewards Program",
    description:
      "Dive deep into the specifics of the rewards program. Check the earning rates, redemption options, and any limitations or expiration dates on points or miles. Some programs may have blackout dates or restrictions on how you can use your rewards, so ensure the program fits your lifestyle and spending habits.",
  },
  {
    title: "Look at Additional Cardholder Benefits:",
    description:
      "Beyond rewards, many credit cards offer additional benefits like purchase protection, extended warranties, travel insurance, and access to exclusive events. These perks can add significant value and should be considered when choosing a card.",
  },
];
export const KNOWLEDGE_PAGE_DATA_PERSONAL_LOANS = {
  title: "Things to Know Before Applying for a",
  description: "Personal Loan",
  sub_description:
    "Applying for a personal loan can be a smart financial move, but it’s crucial to be well-informed before proceeding. Before applying for a personal loan, consider these key points to make an informed decision.",
};
export const KNOWLEDGE_PAGE_DATA_CREDIT_CARDS = {
  title: "Why Consider Applying for a",
  description: "Credit Card ?",
  sub_description:
    "Credit cards offer a variety of features and benefits depending on the type of card you choose. However, some features are common across most credit cards:",
};
export const KNOWLEDGE_POINTS_PERSONAL_LOANS: ContentProps[] = [
  {
    title: "Importance of Credit Score",
    description:
      "Your credit score plays a vital role in loan approval and interest rates:",
    points: [
      {
        label: "Higher Approval Rates:",
        value:
          "A credit score of 750 and above significantly increases your chances of loan approval.",
      },
      {
        label: "Better Interest Rates:",
        value:
          "Lenders often offer lower interest rates to applicants with higher credit scores.",
      },
      {
        label: "Pre-Approved Loan Eligibility:",
        value:
          " A good credit score enhances your eligibility for pre-approved offers.",
      },
    ],
  },
  {
    title: "Prepayment and Foreclosure Charges",
    description:
      "Understanding charges related to early loan repayment is essential:",
    points: [
      {
        label: "Floating Rate Loans:",
        value:
          "The Reserve Bank of India (RBI) prohibits banks and NBFCs from charging prepayment or foreclosure fees on floating rate personal loans.",
      },
      {
        label: "Fixed Rate Loans:",
        value:
          "Lenders may levy charges on prepaying or foreclosing fixed rate loans, so review the terms carefully.",
      },
    ],
  },
  {
    title: "Impact of Multiple Loan Applications",
    description:
      "Submitting multiple loan applications in a short period can harm your credit score:",
    points: [
      {
        label: "Hard Enquiries:",
        value:
          "Each loan application triggers a hard enquiry on your credit report",
      },
      {
        label: "Credit Score Decline:",
        value:
          "Multiple hard enquiries within a short time frame can lower your credit score, reducing your chances of loan approval.",
      },
    ],
  },
  {
    title: "Instant Pre-Approved Offers",
    description:
      "Banks and NBFCs often provide instant pre-approved personal loans to current customers. Here’s what you need to know:",
    points: [
      {
        label: "Quick Disbursals:",
        value: "Pre-approved offers feature fast disbursement of funds.",
      },
      {
        label: "Minimal Documentation:",
        value: "These loans often require little to no paperwork.",
      },
      {
        label: "Lower Interest Rates:",
        value: "Existing customers may benefit from reduced interest rates.",
      },
    ],
  },
  {
    title: "Choosing Loan Tenure",
    description: "The tenure of your loan affects your monthly payments:",
    points: [
      {
        label: "Longer Tenure, Lower EMIs:",
        value:
          "Opting for a longer repayment period results in lower Equated Monthly Instalments (EMIs).",
      },
      {
        label: "Manageable Payments:",
        value:
          "Select a tenure that balances your repayment ability with comfortable EMIs.",
      },
    ],
  },
];
export const KNOWLEDGE_POINTS_CREDIT_CARDS: ContentProps[] = [
  {
    title: "Credit Building",
    description:
      "Responsible use of a credit card helps build a strong credit profile, which is beneficial for securing loans for major life goals such as purchasing a home or car.",
  },
  {
    title: "Additional Cards",
    description:
      "You can request additional cards for family members such as your spouse, children, or parents, allowing them to share the benefits of your primary card.",
  },
  {
    title: "Fuel Purchase Savings:",
    description:
      "Most credit cards offer a 1% waiver on fuel surcharges. The applicable transaction value and maximum monthly waiver amount may vary between cards.",
  },
  {
    title: "Insurance Coverage",
    description:
      "Some credit cards come with complimentary insurance coverage for air accidents, emergency hospitalization, lost card liability, and loss of luggage or documents during travel.  ",
  },
  {
    title: "Convenient Access to Funds",
    description:
      "The credit limit provided by your card enhances your purchasing power and can be especially useful during financial emergencies.",
  },
  {
    title: "Rewards and Cashback",
    description:
      "Many credit cards offer rewards or cashback on purchases. Some cards provide reward points, while others offer cashback or direct discounts with select brands.",
  },
  {
    title: "EMI Conversion",
    description:
      "Credit cards enable you to make large purchases and convert them into Equated Monthly Installments (EMIs), making it easier to manage payments over several months.",
  },
  {
    title: "Rewards and Cashback",
    description:
      "Many credit cards offer rewards or cashback on purchases. Some cards provide reward points, while others offer cashback or direct discounts with select brands.",
  },
  {
    title: "Airport Lounge Access",
    description:
      "Many credit cards, not just travel-specific ones, offer complimentary access to airport lounges. The number of free visits per month, quarter, or year differs from card to card. ",
  },
];
export const ONLINE_STEPS_PERSONAL_LOANS_PAGE_DATA = {
  title: "Apply for a Personal Loan online in",
  description: "3 quick steps",
};
export const ONLINE_STEPS_CREDIT_CARDS_PAGE_DATA = {
  title: "Get Your Ideal Credit Card in",
  description: "3 Simple Steps",
};
export const ONLINE_STEPS_PERSONAL_LOANS: ContentProps[] = [
  {
    title: "Fill Out the Form",
    description:
      "Provide your basic details and loan requirements in our user-friendly form below.",
    image_url: require("../../assets/step1.svg")?.default,
  },
  {
    title: "Get Instant Approval",
    description:
      "Our automated system will review your application and provide instant approval.",
    image_url: require("../../assets/step2.svg")?.default,
  },
  {
    title: "Receive Your Funds",
    description:
      "Once approved, the funds will be transferred directly to your bank account.",
    image_url: require("../../assets/step3.svg")?.default,
  },
];
export const ONLINE_STEPS_CREDT_CARDS: ContentProps[] = [
  {
    title: "Compare Cards ",
    description:
      "Use our comparison tool to find the best credit cards for your needs.",
    image_url: require("../../assets/step1.svg")?.default,
  },
  {
    title: "Check Eligibility",
    description: "See your approval odds without affecting your credit score.",
    image_url: require("../../assets/step2.svg")?.default,
  },
  {
    title: "Apply Online",
    description:
      "Complete a quick application and receive an instant decision.",
    image_url: require("../../assets/step3.svg")?.default,
  },
];
export const FAQ: FAQProps[] = [
  {
    id: "1",
    title: "How do I apply for a credit product through CredNow?",
    description:
      "Searching and applying for a loan or credit card through CredNow is simple and convenient. Log into your CredNow account using your registered phone number. Once you are logged in, you will get access to several credit options specifically designed to meet your financial needs. It only takes a few clicks to get the financial help you require with CredNow.",
  },
  {
    id: "2",
    title:
      "What is the eligibility criteria for applying for loans through CredNow?",
    description:
      "The eligibility criteria depend on the type of credit product you are seeking and the lender's guidelines. Typically, financial institutions assess factors including credit score, income, employment status, and repayment capability. Specific document requirements will be communicated to you during the application process.",
  },
  {
    id: "3",
    title: "What is considered a good credit score?",
    description:
      "Your Bureau score from CIBIL/Equifax/Experian/CRIF, ranging from 300 to 900, reflects your credit behavior and health. Generally, a score above 700 is considered good. A higher credit score increases your likelihood of qualifying for a loan or credit card.",
  },
  {
    id: "4",
    title: "What documents do I need to avail myself of a credit product?",
    description:
      "The documents needed can vary based on the type of credit product (Credit Card, Personal Loan, Business Loan, Micro Loan) and the lender's criteria. Typically, you'll be asked for proof of identity (such as an Aadhar card or PAN card, proof of address, income documents (such as salary slips or income tax returns), and bank statements. During the application process, specific document requirements will be communicated to you.",
  },
  {
    id: "5",
    title: "Are there any fees associated with using CredNow's services?",
    description:
      "While there are no fees for using CredNow, there might be processing fees or service charges depending on the specific credit product you apply for. For detailed information, review the terms and conditions provided by the banks or lenders on CredNow.",
  },
];
export const PERSONAL_LOANS_FAQ: FAQProps[] = [
  {
    id: "1",
    title: "What is an instant personal loan ?",
    description:
      "An instant personal loan is typically disbursed within minutes of application, often pre-approved for existing customers with good credit histories. In contrast, a regular personal loan involves a more thorough application and approval process.",
  },
  {
    id: "2",
    title: "Which bank offers the best personal loan ?",
    description:
      "The best personal loan offers vary depending on individual financial needs and eligibility criteria. Some of the top banks known for their personal loan offers include HDFC Bank, ICICI Bank, State Bank of India (SBI), Axis Bank, and IndusInd Bank. It's advisable to compare interest rates, processing fees, and repayment terms to find the best option for you.",
  },
  {
    id: "3",
    title: "How quick is the application process ?",
    description:
      "CredNow offers a simple, fast application process designed to provide quick financial assistance.",
  },
  {
    id: "4",
    title: " What credit score do I need for a personal loan ?",
    description:
      "A credit score of 750 or higher generally improves your chances of getting a personal loan. However, many banks and NBFCs also provide loans to individuals with lower credit scores, though typically at higher interest rates due to the increased risk.",
  },
  {
    id: "5",
    title: "What is the maximum loan amount I can apply for ?",
    description:
      "You can apply for loans ranging from INR 10,000 to INR 5,00,000, depending on your eligibility.",
  },
  {
    id: "6",
    title: "Are there any hidden fees ?",
    description:
      "No, our process is completely transparent with no hidden charges.",
  },
  {
    id: "7",
    title: "What documents do I need to provide ?",
    description:
      "Basic documents such as identity proof, address proof, and income proof are required.",
  },
  {
    id: "8",
    title: "Who can apply for a personal loan with CredNow ?",
    description:
      "Anyone in need of quick financial assistance can apply. We cater to a wide range of financial needs.",
  },
  {
    id: "9",
    title: "What can I use a CredNow personal loan for ?",
    description:
      "Our loans can be used for various purposes including: Medical emergencies, Home renovations, Education expenses and Other financial needs.",
  },
  {
    id: "10",
    title: "What are the best personal loan offers in India ?",
    description:
      "The best personal loan offers in India can be found from various banks and NBFCs such as HDFC Bank, ICICI Bank, State Bank of India (SBI), Axis Bank, IndusInd Bank, and Punjab National Bank (PNB). These lenders offer competitive interest rates, flexible repayment options, and minimal processing fees. It's essential to compare different offers to find the one that suits your financial requirements.",
  },
  {
    id: "11",
    title: "What is the maximum duration for repaying a personal loan ?",
    description:
      "Personal loan repayment periods usually range from 1 to 5 years. Some lenders may offer extended terms of up to 6 to 8 years.",
  },
  {
    id: "12",
    title:
      "Do I need to provide any security or collateral to get a personal loan ?",
    description:
      "Personal loans are generally unsecured, meaning no collateral is required. However, some lenders offer secured personal loans, which do require collateral. This option may be useful if you're not eligible for an unsecured loan or if the interest rates are significantly higher for unsecured options.",
  },
  {
    id: "13",
    title: "Can I use a personal loan for business purposes ?",
    description:
      "Yes, you can use a personal loan to finance various business needs, but they're not permitted for speculative ventures.",
  },
];
export const CREDIT_CARDS_FAQ: FAQProps[] = [
  {
    id: "1",
    title:
      "What are the basic eligibility criteria for applying for a credit card in India ?",
    description:
      "The basic eligibility criteria for applying for a credit card in India generally include Age: Applicants must be at least 18 years old and not older than 60 years, Credit Score: A credit score of 700 or above is preferred, Employment Status: Applicants can be either salaried or self-employed, Minimum Income: The minimum income requirement varies depending on the type of credit card, its benefits, and the annual fee",
  },
  {
    id: "2",
    title: "What is the preferred credit score for credit card eligibility ?",
    description:
      "A credit score of 700 and above is preferred for credit card eligibility. However, different issuers may have varying requirements, and a higher credit score can increase the chances of approval for premium credit cards.",
  },
  {
    id: "3",
    title: "What is the best credit card with the lowest interest rate ?",
    description:
      "The best low-interest credit card varies depending on your credit profile and needs. Some top options include SBI's SimplySAVE Card, HDFC's Platinum Edge Card, and ICICI Bank's Platinum Chip Card. Compare offers on CredNow to find the lowest rates available to you. ",
  },
  {
    id: "4",
    title: "What is the best travel credit card ?",
    description:
      "The best travel credit card depends on your travel habits and preferences. Popular options include [Bank Name]'s Travel Card and [Bank Name]'s Miles Card. These cards offer benefits like travel insurance, lounge access, and accelerated mile earning.",
  },
  {
    id: "5",
    title: "How do I determine which credit card is best for me ?",
    description:
      "To find the best credit card for you, consider your spending habits, credit score, and financial goals. Use CredNow's comparison tool to evaluate different cards based on rewards, fees, and benefits that align with your needs.",
  },
  {
    id: "6",
    title: "How quickly can I get approved for a credit card ?",
    description:
      "Many credit card issuers offer instant approval decisions online. However, in some cases, it may take a few days for a final decision, especially if additional information is required.",
  },
  {
    id: "7",
    title: "Can I apply for a credit card if I have a low credit score ?",
    description:
      "Yes, there are credit cards designed for individuals with lower credit scores. These may include secured credit cards or cards with higher interest rates. CredNow can help you find options suited to your credit profile.",
  },
  {
    id: "8",
    title:
      "Are there any fees for using CredNow's credit card comparison service ?",
    description:
      "No, CredNow's credit card comparison and application services are completely free for users. ",
  },
  {
    id: "9",
    title: "Understanding Your Credit Limit",
    description:
      "Your credit limit is the maximum amount you can charge to your credit card. This limit is determined by the bank based on factors like your credit score, credit history, and repayment behavior. To maintain a good credit score, avoid frequently reaching or exceeding your credit limit.",
  },
  {
    id: "10",
    title: "Understanding Your Credit Card Statement",
    description:
      "A credit card statement provides a detailed summary of all your credit card transactions within a billing cycle. It includes purchases, payments, credits, and any applicable charges. The statement also lists important information like your credit limit, total amount due, payment due date, and minimum payment required.",
  },
  {
    id: "11",
    title: "What is the Credit Utilization Ratio ?",
    description:
      "The credit utilization ratio measures the amount of credit you've used relative to your total credit limit. For example, if your credit limit is Rs. 1 Lakh and you've spent Rs. 28,000, your credit utilization ratio is 28%. It's recommended to keep this ratio around 30% across all your credit cards to maintain a healthy credit score.",
  },
  {
    id: "12",
    title: "What is the Minimum Due on a Credit Card ?",
    description:
      "The minimum due on your credit card is the smallest amount you need to pay by the due date to keep your account in good standing. Typically, this amount is 5% of your outstanding balance. ",
  },
  {
    id: "13",
    title: "How to Use Your Credit Card Securely ?",
    description:
      "To ensure your credit card remains secure, never share your card details such as the card number, expiration date, CVV, PIN, or OTPs with anyone. Regularly monitor your card transactions and immediately report any suspicious or unauthorized activity. If your card is lost or stolen, notify your card issuer right away.",
  },
];
export const BENEFITS: string[] = [
  "Best online personal loan offers from our network of top lenders",
  "A simple and user-friendly application process",
  "Minimal paperwork, quick approvals and transparent terms",
  "Flexible loan options to suit your financial need",
  "Reliable support throughout your loan journey",
];
export const OTHER_OPTIONS_PERSONAL_LOANS: ContentProps[] = [
  {
    title: "Credit Cards",
    description: "Rewards, Cashback, Travel benefits, and more await! ",
    link: CATEGORIES.CREDIT_CARDS,
    image_url: require("../../assets/credit_v2.svg")?.default,
  },
  {
    title: "Micro Loans (Under 5 lakhs)",
    description:
      "Micro loan offers designed for quick processing with minimal paperwork .",
    link: CATEGORIES.MICRO_LOANS,
    image_url: require("../../assets/micro_v2.svg")?.default,
  },
];
export const OTHER_OPTION_CREDIT_CARDS: ContentProps[] = [
  {
    title: "Personal Loans",
    description: "Rewards, Cashback, Travel benefits, and more await! ",
    link: CATEGORIES.PERSONAL_LOAN,
    image_url: require("../../assets/credit_v2.svg")?.default,
  },
  {
    title: "Micro Loans (Under 5 lakhs)",
    description:
      "Micro loan offers designed for quick processing with minimal paperwork .",
    link: CATEGORIES.MICRO_LOANS,
    image_url: require("../../assets/micro_v2.svg")?.default,
  },
];
export const LOGIN_FORM_STEPS: ContentProps[] = [
  {
    id: 1,
    title: "Verify Your Mobile Number",
    description: "Discover Your Loan Options – Begin with Your Number!",
  },
  {
    id: 2,
    title: "PAN Verification",
    description: "Secure and Speedy – Just Verify Your PAN!",
  },
  {
    id: 3,
    title: "Personal Details",
    description: "Personal Details to Help Us Find the Right Loan for You!",
  },
  {
    id: 4,
    title: "Type of Employment",
    description: "Share Your Employment Type to Unlock Your Loan Options!",
  },
];
//carousel props
export const CarouselResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tab: {
    breakpoint: { max: 1023, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

//slider mark options
export const slider_marks_micro_loans = [
  { label: "From ₹0", value: 0 },
  { label: "", value: 5000 },
  { label: "", value: 10000 },
  { label: "", value: 15000 },
  { label: "", value: 20000 },
  { label: "", value: 25000 },
  { label: "", value: 30000 },
  { label: "", value: 35000 },
  { label: "", value: 40000 },
  { label: "", value: 45000 },
  { label: "To ₹50,000", value: 50000 },
];

//slider mark options
export const slider_marks_personal_loans = [
  { label: "From ₹0", value: 0 },
  { label: "", value: 100000 },
  { label: "", value: 200000 },
  { label: "", value: 300000 },
  { label: "", value: 400000 },
  { label: "To ₹5,00,000", value: 500000 },
];

//slider mark options
export const slider_marks_personal_loans_v2 = [
  { label: "₹0", value: 0 },
  { label: "", value: 50000 },
  { label: "", value: 100000 },
  { label: "", value: 150000 },
  { label: "", value: 200000 },
  { label: "", value: 250000 },
  { label: "", value: 300000 },
  { label: "", value: 350000 },
  { label: "", value: 400000 },
  { label: "", value: 450000 },
  { label: "₹5,00,000", value: 500000 },
];
//slider mark options
export const slider_marks_months_options = [
  { label: "0", value: 0 },
  { label: "", value: 6 },
  { label: "", value: 12 },
  { label: "", value: 18 },
  { label: "", value: 24 },
  { label: "", value: 30 },
  { label: "", value: 36 },
  { label: "", value: 42 },
  { label: "", value: 48 },
  { label: "", value: 54 },
  { label: "", value: 60 },
  { label: "", value: 66 },
  { label: "72 Months", value: 72 },
];

//slider mark options
export const slider_marks_other_loans = [
  { label: "From ₹0", value: 0 },
  { label: "", value: 1000000 },
  { label: "", value: 2000000 },
  { label: "", value: 3000000 },
  { label: "", value: 4000000 },
  { label: "To ₹50,00,000", value: 5000000 },
];

//slider mark options
export const slider_interest_rate_options = [
  { label: "10%", value: 10 },
  { label: "", value: 14 },
  { label: "", value: 18 },
  { label: "", value: 22 },
  { label: "", value: 26 },
  { label: "30%", value: 30 },
];
//helper function for detecting the screen
export const handleResize = () => {
  var screenWidth =
    window?.innerWidth ||
    document?.documentElement?.clientWidth ||
    document?.body?.clientWidth;
  var mobileBreakpoint = 767;
  var tabletBreakpoint = 1024;
  if (screenWidth <= mobileBreakpoint) {
    store.dispatch(setMobile(true));
    store.dispatch(setTab(false));
  } else if (screenWidth <= tabletBreakpoint) {
    store.dispatch(setMobile(false));
    store.dispatch(setTab(true));
  } else {
    store.dispatch(setMobile(false));
    store.dispatch(setTab(false));
  }
};
//helper function to modify the name and display on the UI
export const nameModifier = (val: string) => val?.replace("_", " ");

//helper function to get the deeplink data from params url
export const getUTMDetails = (
  queryData: URLSearchParams
): Record<string, string> => {
  return utmParams?.reduce((details, param) => {
    details[param] = queryData?.get(param) || "";
    return details;
  }, {} as Record<string, string>);
};

//helper function for opening t&c and policy control documents
export const openDocuments = (type: string) => {
  switch (type) {
    case "t&c":
      window.open(process.env.REACT_APP_TERMS_CONDITIONS_POLICY_LINK, "_blank");
      break;
    case "privacy_policy":
      window.open(process.env.REACT_APP_PRIVACY_POLICY_LINK, "_blank");
      break;
    default:
      break;
  }
};

//helper function to create groups for leads pagination functionality
export const createGroups = (
  rangeStart: number,
  rangeEnd: number,
  windowSize: number
) => {
  const groups: any = {};
  let start = rangeStart;
  let index = 0;
  while (start <= rangeEnd) {
    let end = start + windowSize - 1;
    if (end > rangeEnd) end = rangeEnd;
    groups[index] = { min: start, max: end };
    start = end + 1;
    index++;
  }
  return groups;
};

//helper function to redirect to the specific pages after lead creation
export const doRedirection = (
  navigate: NavigateFunction,
  url: string,
  selectedCategory: string = ""
) => {
  //helper function to return the url of the pages on the basis of categories
  const returnURL = () => {
    switch (selectedCategory) {
      case CATEGORIES.PERSONAL_LOAN:
      case CATEGORIES.MICRO_LOANS:
        return ROUTES_PATHS.PERSONAL_LOAN;
      case CATEGORIES.CREDIT_CARDS:
        return ROUTES_PATHS.CREDIT_CARD;
      default:
        return ROUTES_PATHS.HOME;
    }
  };
  setTimeout(() => {
    navigate(returnURL(), { replace: true, state: selectedCategory });
    window.open(url, "_blank");
  }, 1000);
};

//helper function to calculate the emi value
export const calculateEMI = (
  principal: number,
  months: number,
  interest: number
): string => {
  const monthlyInterestRate = interest / 12 / 100;
  const simpleInterest = principal * monthlyInterestRate * months;
  const totalAmount = principal + simpleInterest;
  return `${(totalAmount / months).toFixed(2)}`;
};

//helper function to return the active class of icon on the basis of category selection
export const returnIconClass = (val: string, category: string): string =>
  `icon ${val === category ? "icon_active" : "icon_deactive"}`;

//helper function to return the active class of content on the basis of category selection
export const returnContentClass = (val: string, category: string): string =>
  `content ${val === category ? "content_active" : ""}`;

//helper function to return the active class of category div on the basis of category selection
export const returnCategoryClass = (val: string, category: string): string =>
  `credit_card_option ${val === category ? "category_active" : ""}`;

// Helper function to add the script for setting the token for Reddit tracking
export const addRedditPixel = (): any => {
  try {
    const script = document.createElement("script");
    script.innerHTML = `
      !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
      rdt('init','a2_fk3u46p6hvf7');
      rdt('track', 'PageVisit');
    `;
    return script;
  } catch (e) {
    console.error("Error adding Reddit Pixel script:", e);
    return null;
  }
};

// Helper function to add the script for setting the token for Facebook tracking
export const addFacebookScript = (): any => {
  try {
    const script = document.createElement("script");
    script.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '1569050833647530');fbq('track', 'PageView');`;
    return script;
  } catch (e) {
    console.error("Error adding Facebook script:", e);
    return null;
  }
};

//helper function to return the Personal Loan page schema
export const PersonalLoanPageSchema = () => {
  return {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: "Personal Loan",
    image:
      "https://crednow.in/static/media/logo.5b122176d870f136d264adbec4d9cd84.svg",
    description:
      "Explore CredNow for the best personal loan in India. Enjoy competitive rates and quick approvals. Start here!",
    brand: "CredNow",
  };
};

//helper function to return the Credit Card loan page schema
export const CreditCardPageSchema = () => {
  return {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: "Credit Cards",
    image:
      "https://crednow.in/static/media/logo.5b122176d870f136d264adbec4d9cd84.svg",
    description:
      "Find the best credit cards in India at CredNow. Explore best credit cards with top rewards, low fees and exclusive benefits await Apply now!",
    brand: "CredNow",
  };
};

//helper function to return the Home Page schema
export const HomePageSchema = () => {
  return {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "How do I apply for a credit product through CredNow?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Searching and applying for a loan or credit card through CredNow is simple and convenient. Log into your CredNow account using your registered phone number. Once you are logged in, you will get access to several credit options specifically designed to meet your financial needs. It only takes a few clicks to get the financial help you require with CredNow.",
        },
      },
      {
        "@type": "Question",
        name: "How do I apply for a credit product through CredNow?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "the eligibility criteria for credit products depend on various factors like credit score, income, employment status, and repayment capability. It also notes that specific document requirements will be communicated during the application process.",
        },
      },
      {
        "@type": "Question",
        name: "What is considered a good credit score?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Your Bureau score from CIBIL, Equifax, Experian, or CRIF, which ranges from 300 to 900, reflects your credit behavior and health. Generally, a score above 700 is considered good. A higher credit score increases your likelihood of qualifying for a loan or credit card.",
        },
      },
      {
        "@type": "Question",
        name: "What documents do I need to avail myself of a credit product?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The documents needed can vary based on the type of credit product (Credit Card, Personal Loan, Business Loan, Micro Loan) and the lender's criteria. Typically, you'll be asked for proof of identity (such as an Aadhar card or PAN card), proof of address, income documents (such as salary slips or income tax returns), and bank statements. During the application process, specific document requirements will be communicated to you.",
        },
      },
      {
        "@type": "Question",
        name: "Are there any fees associated with using CredNow's services?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "While there are no fees for using CredNow, there might be processing fees or service charges depending on the specific credit product you apply for. For detailed information, review the terms and conditions provided by the banks or lenders on CredNow.",
        },
      },
    ],
  };
};

// helper function to check the employment form validations
export const checkEmploymentForm = (data: ObjectBasicProps) => {
  const {
    occupation,
    profession,
    residence_type,
    residence_years,
    pincode,
    salary_mode,
    qualification,
    institue_name,
  } = data || {};
  if (occupation?.toLowerCase() === "salaried")
    return (
      profession?.length &&
      residence_type?.length &&
      residence_years?.length &&
      pincode?.toString()?.length &&
      salary_mode?.length
    );
  if (occupation?.toLowerCase() === "self employed")
    return (
      profession?.length &&
      residence_type?.length &&
      residence_years?.length &&
      pincode?.toString()?.length
    );
  if (occupation?.toLowerCase() === "student")
    return (
      qualification?.length &&
      institue_name?.length &&
      residence_type?.length &&
      residence_years?.length &&
      pincode?.toString()?.length
    );
  return false;
};

//helper function to validate the form values section wise
export const formValidations = (id: number = 0) => {
  const { auth } = store?.getState();
  const { user_details } = store?.getState()?.config;
  switch (id) {
    case 1:
      return user_details?.mobile?.length && auth?.token?.length;
    case 2:
      return (
        user_details?.pan_number?.length &&
        user_details?.vendor_customer_id?.length
      );
    case 3:
      return (
        user_details?.name?.length &&
        user_details?.gender?.length &&
        user_details?.dob?.length &&
        user_details?.email?.length &&
        user_details?.required_loan_amount?.toString()?.length
      );
    case 4:
      return checkEmploymentForm(user_details);
    default:
      return false;
  }
};

export const handleScroll = (id: string) => {
  setTimeout(() => {
    const targetDiv = document.getElementById(id);
    window?.scrollTo({
      top:
        (targetDiv?.getBoundingClientRect()?.top || 0) +
        window?.pageYOffset -
        100,
      behavior: "smooth",
    });
  }, 500);
};

//helper function to return the headings of the form steps
export const returnStepHeaders = (id: number = 0) => {
  switch (id) {
    case 1:
      return {
        current: "Verify Your Mobile Number",
        next: "Next - PAN Verification",
      };
    case 2:
      return { current: "PAN Verification", next: "Next- Personal Details" };
    case 3:
      return { current: "Personal Details", next: "Next-Type of Employment" };
    case 4:
      return { current: "Type of Employment" };
  }
};

//helper function to return the completed form number
export const returnCompletedStepValue = (id: number) => {
  switch (id) {
    case 1:
      return formValidations(1)
        ? { val: 1, progress: 25 }
        : { val: 0, progress: 0 };
    case 2:
      return formValidations(2)
        ? { val: 2, progress: 50 }
        : { val: 1, progress: 25 };
    case 3:
      return formValidations(1) && formValidations(2) && formValidations(3)
        ? { val: 3, progress: 75 }
        : { val: 2, progress: 50 };
    case 4:
      return formValidations(1) &&
        formValidations(2) &&
        formValidations(3) &&
        formValidations(4)
        ? { val: 4, progress: 100 }
        : { val: 3, progress: 75 };
    default:
      return { val: 0, progress: 0 };
  }
};

//helper function for calling the logout api
const { PAYMENT_USER, CREDNOW } = API_SERVICE;
const {
  PAYMENT_USER: { LOGOUT },
  CREDNOW: { FETCH_USER_DATA },
} = API_ENDPOINTS;

//helper function to logout the user
export const callLogout = () => {
  PAYMENT_USER.post(LOGOUT, {})
    .then((res: AxiosResponse<ApiResponse<{ data: Object }>>) => {
      const { message, success } = res?.data;
      if (success) {
        window.location.href = "/";
        toast?.success(message);
        store.dispatch(logout());
        localStorage.clear();
      } else {
        toast.dismiss();
        toast.error(DEFAULT_ERROR_MESSAGE);
      }
    })
    .catch((err: AxiosError) => {
      toast.dismiss();
      toast.error(err?.message || DEFAULT_ERROR_MESSAGE);
    });
};

//helper function to get the user details
export const getUserDetails = (mobile: string) => {
  CREDNOW.get(FETCH_USER_DATA, { params: { mobile: mobile } })
    .then((res: AxiosResponse<ApiResponse<any>>) => {
      const { success, data } = res?.data;
      if (success) store.dispatch(saveUserDetails({ ...data }));
    })
    .catch((err: AxiosError) => {
      toast.dismiss();
      toast.error(err?.message || DEFAULT_ERROR_MESSAGE);
    });
};
