import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//helper functions imports
import { AuthData } from "../../../helpers/interface/common";

const initialState: AuthData = {
  token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    //function for setting the token if the otp is success
    login(state, action: PayloadAction<string>) {
      state.token = action?.payload;
    },
  },
});

export const { login } = authSlice.actions;
export default authSlice.reducer;
