//material  imports
import { CircularProgress, Box } from "@mui/material";

//css imports
import "./Loader.scss";

const Loader = ({ color = "" }: { color?: string }) => {
  return (
    <Box className="loader_overlay" sx={{ background: color }}>
      <Box className="loader_object">
        <CircularProgress sx={{ color: "rgba(77, 44, 94, 1)" }} />
      </Box>
    </Box>
  );
};
export default Loader;
