export const SERVICES: {
  service_key: string;
  service_name: string;
  baseURL?: string;
}[] = [
  {
    service_key: "payment-user",
    service_name: "PAYMENT_USER",
    baseURL: process.env.REACT_APP_PAYMENT_USER_SERVICE,
  },
  {
    service_key: "crednow",
    service_name: "CREDNOW",
    baseURL: process.env.REACT_APP_CREDNOW_SERVICE,
  },
  {
    service_key: "wp-json",
    service_name: "BLOGS",
    baseURL: process.env.REACT_APP_BLOGS_SERVICE,
  },
];
export const API_ENDPOINTS = {
  PAYMENT_USER: {
    LOGIN: "/v2/login",
    LOGOUT: "/logout",
  },
  CREDNOW: {
    FETCH_USER_DATA: "/customer/details",
    PRODUCT_CATEGORIES: "/public/category",
    PRODUCT_DETAILS: "/product/details",
    PRODUCT_DETAILS_PUBLIC: "/public/product/details",
    PRODUCT_DETAIL_PRODUCT_ID_WISE: "/public/product/detail",
    SAVE_CUSTOMER_DETAILS: "/customer/details",
    CREATE_LEAD: "/customer/lead",
    GET_LEAD_IDS: "/customer/leads",
    GET_LEAD_STATUS: "/customer/lead/status",
  },
  BLOGS: {
    BLOGS_FETCH_URL: "/wp/v2/posts",
  },
};
