import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { toast } from "cg-toast";

//helper functions imports
import { SERVICES } from "./constants";
import {
  DEFAULT_ERROR_MESSAGE,
  UNAUTHORIZED_MESSAGE,
} from "../constants/utlis";
import { store } from "../../store";
import { logout } from "../../store/logout";

const API_SERVICE: { [key: string]: AxiosInstance } = {};
SERVICES?.forEach(
  (service: {
    service_name: string;
    service_key: string;
    baseURL?: string;
  }) => {
    const { service_name, service_key, baseURL = "" } = service;
    API_SERVICE[service_name] = axios.create({
      baseURL: `${baseURL}/${service_key}`,
      headers: { "Content-Type": "application/json" },
    });

    API_SERVICE[service_name]?.interceptors?.request?.use(
      (config: any) => {
        config.headers.Authorization = `Bearer ${
          store.getState()?.auth?.token
        }`;
        return config;
      },
      (error: AxiosError) =>
        Promise.resolve({
          success: false,
          ...(error?.response?.data ?? { message: DEFAULT_ERROR_MESSAGE }),
        })
    );

    API_SERVICE[service_name].interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        const { response: { status } = {} } = error;
        if (status === 401) {
          toast.dismiss();
          toast.error(UNAUTHORIZED_MESSAGE);
          store.dispatch(logout());
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        }
        return Promise.reject({
          success: false,
          ...(error?.response?.data ?? { message: DEFAULT_ERROR_MESSAGE }),
        });
      }
    );
  }
);
export default API_SERVICE;
