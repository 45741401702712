import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//helper functions imports
import { ConfigProps } from "../helpers/interface/common";

const initialState: ConfigProps = {
  user_details: {},
  mb: false,
  tab: false,
  deeplink: false,
};

const configSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    //function to set whether it is deeplink flow or not
    saveDeeplinkStatus(state, action: PayloadAction<boolean>) {
      state.deeplink = action?.payload;
    },
    //function to save the userDetails
    saveUserDetails(state, action: PayloadAction<any>) {
      state.user_details = action?.payload;
    },
    //function for setting whether the screen is mobile or not
    setMobile(state, action: PayloadAction<boolean>) {
      state.mb = action?.payload;
    },
    //function for setting whether the screen is tab or not
    setTab(state, action: PayloadAction<boolean>) {
      state.tab = action?.payload;
    },
  },
});

export const { setMobile, setTab, saveUserDetails, saveDeeplinkStatus } =
  configSlice?.actions;
export default configSlice?.reducer;
