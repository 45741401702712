import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

//reducer functions imports
import AuthReducer from "../pages/Auth/slice/index";
import ConfigReducer from "./config";
import { LOGOUT } from "./logout";

const rootReducer = combineReducers({
  auth: AuthReducer,
  config: ConfigReducer,
});
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "config"],
};
const persistedReducer = persistReducer(persistConfig, (state: any, action) => {
  if (action?.type === LOGOUT) {
    state.auth = undefined;
    state.config = { mb: state?.config?.mb, tab: state?.config?.tab };
  }
  return rootReducer(state, action);
});
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
